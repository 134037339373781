<template lang="pug">
page-wrapper(:title="title")
  template(slot="content")
    om-body-text.mb-2(bt400md) {{ $t('codeInsertV2.manual.connect') }}
    .d-flex.align-items-center.mb-6(v-if="domain")
      om-heading(h6) {{ domain }}
      om-button.ml-2(v-if="showChange" ghost primary small @click="changeDomain") {{ $t('change') }}
    om-heading.mb-4(h4) {{ $t('codeInsertV2.manual.whatType') }}
    .d-flex.flex-wrap
      om-select-card.col-6.col-xl-4(
        v-for="option in options"
        :title="option.key"
        :key="option.value"
        @click="navigateToPlatform(option.value)"
        :arrow="false"
      )
        template(#backgroundContent)
          span
        template(#iconContent)
          img(:src="require(`@/assets/admin/svg/insertcode/${option.icon}.svg`)")
    om-heading.mb-4(h4) {{ $t('codeInsertV2.manual.gtag') }}
    .d-flex.w-100
      om-select-card.col-6.col-xl-4(
        :title="this.$t(`codeInsertV2.platforms.gtag`)"
        key="gtag"
        @click="navigateToPlatform('tagmanager')"
        :arrow="false"
      )
        template(#backgroundContent)
          span
        template(#iconContent)
          img(:src="require(`@/assets/admin/svg/insertcode/option_gtag.svg`)")
    om-button.mt-4(ghost icon="question-circle" @click="openHelpModal") {{ $t('codeInsertV2.buttons.help') }}
    portal(to="root")
      insert-code-help-v2
  template(slot="image")
    om-monks(monk="hi-user")
</template>

<script>
  import { PageWrapper } from '@/components/CodeInsert';
  import childRouteMixin from '@/mixins/codeinsert/childRoute';
  import { getLang } from '@/router/utils';
  import { ONLY_GUIDE_SCREEN } from './constants';

  const platforms = [
    'custom',
    'magento',
    ...(getLang() === 'hu' ? ['unas', 'shoprenter'] : []),
    'bigcommerce',
    'wordpress',
    'shopify',
    'other',
  ];

  export default {
    components: {
      PageWrapper,
      InsertCodeHelpV2: () => import('@/components/Modals/InsertCode/Help.vue'),
    },
    mixins: [childRouteMixin],

    computed: {
      title() {
        return this.$route.name.includes('settings')
          ? this.$t('codeInsertV2.titles.connect')
          : this.$t('codeInsertV2.titles.almostThere');
      },
      showChange() {
        return !['shopify'].includes(this.platform);
      },
      options() {
        return platforms.map((p) => ({
          value: p,
          key: this.$t(`codeInsertV2.platforms.${p}`),
          icon: p === 'custom' ? 'option_html_bg' : `option_${p}`,
        }));
      },
    },

    methods: {
      navigateToPlatform(platform) {
        const correctedPlatform = this.correctPlatform(platform);
        const page = ONLY_GUIDE_SCREEN.includes(correctedPlatform) ? 'platform-setup' : 'platform';
        const name = this.$route.name.replace('manual', page);
        this.$router.push({
          name,
          query: this.$route.query,
          params: { platform: correctedPlatform },
        });
      },
      changeDomain() {
        if (this.isOnboarding) {
          this.$router.push({ name: 'code-insert-domain' });
        } else {
          this.$router.push({ name: 'domains' });
        }
      },
      openHelpModal() {
        this.$modal.show('insert-code-help-v2');
      },
    },
  };
</script>
<style lang="sass">
  .brand-select-wrapper
    margin: 0
    padding-left: 0
    padding-right: 20px
    padding-bottom: 20px
  .brand-select-card
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.04)
    border-radius: 8px
    height: 70px
    padding-left: 4.6875rem
  .brand-select-card-svg
    left: -0.5rem
  .brand-select-card-title
    font-weight: 500
    font-size: 14px
    line-height: 20px
</style>
